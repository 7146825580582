import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Aricles({pageTitle}) {

  return (
   <section id="insight">
      <div className="insight">
        <div className="title">
          <h2>Insight Articles</h2>
        </div>
        <div className="articles">

          <div className="owl-carousel owl-theme owl-news">
          <div className="item">
              <div className="article">
                <div className="picture">
                  <AniLink cover to="/reactjs-headless-cms-foundation-care" bg="#00ccd4" direction="right" duration={0.76}><img src={'/img/articles/headless-foundation-care-colours.jpg'} alt="ReactJS Headless CMS" /></AniLink>
                </div>
                <div className="shadow"></div>
                <span className="meta">DEV INSIGHTS | 29 APR 2024</span>
                <h3>Understanding ReactJS, Headless CMS, and Foundation Care</h3>
                <AniLink cover to="/reactjs-headless-cms-foundation-care" bg="#00ccd4" direction="right" duration={0.76} className="read-more">Read more</AniLink>
              </div>
            </div>


            <div className="item">
              <div className="article">
                <div className="picture">
                  <AniLink cover to="/website-speed-performance-optimisation" bg="#00ccd4" direction="right" duration={0.76}><img src={'/img/single/single-news.jpg'} alt="Website Speed and Performance Optimisation" /></AniLink>
                </div>
                <div className="shadow"></div>
                <span className="meta">DEV INSIGHTS | 28 MAR 2017</span>
                <h3>Website Speed and Performance Optimisation</h3>
                <AniLink cover to="/website-speed-performance-optimisation" bg="#00ccd4" direction="right" duration={0.76} className="read-more">Read more</AniLink>
              </div>
            </div>
            <div className="item">
              <div className="article">
                <div className="picture">
                  <AniLink cover to="/improve-search-engines-ranking" bg="#00ccd4" direction="right" duration={0.76}><img src={'/img/services/featured-digital-stragey.jpg'} alt="How to improve in search ranking?" /></AniLink>
                </div>
                <div className="shadow"></div>
                <span className="meta">DEV INSIGHTS | 17 APR 2016</span>
                <h3>How to improve in search ranking?</h3>
                <AniLink cover to="/improve-search-engines-ranking" bg="#00ccd4" direction="right" duration={0.76} className="read-more">Read more</AniLink>
              </div>
            </div>
            <div className="item">
              <div className="article">
                <div className="picture">
                  <AniLink cover to="/6-website-security-tips" bg="#00ccd4" direction="right" duration={0.76}><img src={'/img/single/single-news2.jpg'} alt="6 Security tips to protect your website" /></AniLink>
                </div>
                <div className="shadow"></div>
                <span className="meta">DEV INSIGHTS | 6 FEB 2016</span>
                <h3>6 Security tips to protect your website</h3>
                <AniLink cover to="/6-website-security-tips" bg="#00ccd4" direction="right" duration={0.76} className="read-more">Read more</AniLink>
              </div>
            </div>
          </div> 
          
        </div>
      </div>
    </section>
  )
}
